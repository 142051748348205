<template>
	<div class="page">
		<div class="pageTop">
			<div class="tit">
				<p>{{ lang == 6 ? '多元化和包容性' : 'Diversity and Inclusion' }}</p>
				<div class="yellowLine"></div>
			</div>
			<div v-if="lang == 6">
				宝爱捷（中国）拥抱每个人独一无二的多样性，并在创造多元化的工作环境上实施了坚实的举措。在这里每个人都得到平等尊重的对待，有公正的机会和资源，并将自身多样化的经验和观点融入公司。</div>
			<div v-else>PAIG (China) embraces variety elements that make individuals unique from one another and takes
				solid
				steps in creating a more diverse work environment in which all individuals are treated fairly and
				respectfully,
				have equal access to opportunities and resources, and bring together diverse experiences and
				perspectives. </div>
			<div class="flexBox">
				<div>
					<img src="../../assets/paigWomen/icon4.png" />
					<p>{{ lang == 6 ? '畅所欲言的氛围' : 'Speak Up Culture' }}</p>
				</div>
				<div>
					<img src="../../assets/paigWomen/icon5.png" />
					<p>{{ lang == 6 ? '“无意识偏见”培训项目' : 'Unconscious Bias Training' }}</p>
				</div>
				<div>
					<img src="../../assets/paigWomen/icon6.1.png" />
					<p>{{ lang == 6 ? '女性联盟' : 'Women @ PAIG' }}</p>
				</div>
			</div>
			<div v-if="lang == 6">宝爱捷（中国）致力于将多元化与包容性的影响辐射到组织内部与外部。
				我们相信，通过融入多样化的人才，技能和观点，我们可以更充分的理解客户的需求，持续建立信任并提供超出预期的服务。</div>
			<div v-else>
				PAIG (China) is committed to affecting more D&I changes both within and beyond the organization. The
				ability to
				bring together diverse talent, skillsets and perspectives enables us to fully understand client's need,
				continue
				to build trust and deliver unexpected outcomes.
			</div>
		</div>
		<div class="title">
			<div class="yellowLine"></div>
			<img src="../../assets/paigWomen/logo.png" />
		</div>
		<div class="intro">
			<div class="intro-txt" v-if="lang == 6">
				作为多样和包容文化的重要组成部分，我们成立了<img class="logo"
					src="../../assets/paigWomen/logo.png" />，宝爱捷（中国）的女性联盟。我们的使命是为职场女性赋能，追求“更好的平衡”。
				这亦是企业文化“诚信、合规、责任和信任”的延伸，宝爱捷（中国）是一个大家庭，无关性别，我们相互尊重。
			</div>
			<div class="intro-txt" v-else>
				As a key component of D&I, <img class="logo" src="../../assets/paigWomen/logo.png" />is the female
				network of PAIG
				(China) . Our mission is to empower women in the workplace and carry out the spirit of
				#BalanceForBetter.
				Moreover, is an extension of our corporate culture, which is built on integrity, compliance,
				responsibility and
				trust. At PAIG (China) we are a big family that practices mutual respect, regardless of gender.
			</div>
			<img src="../../assets/paigWomen/IWD.png" />
		</div>
		<div class="mode2">
			<div class="mode-tit">
				<div>{{ lang == 6 ? '我们的愿景' : 'our vision' }}</div>
				<!-- <img src="../../assets/paigWomen/logo.png" /> -->
			</div>
			<div class="mode2-con">
				<div>
					<img src="../../assets/paigWomen/icon1.png" />
					<p>{{ lang == 6 ? '平衡' : 'Balance' }}</p>
				</div>
				<div>
					<img src="../../assets/paigWomen/icon2.png" />
					<p>{{ lang == 6 ? '赋能' : 'empowerment' }}</p>
				</div>
				<div>
					<img src="../../assets/paigWomen/icon3.png" />
					<p>{{ lang == 6 ? '联结' : 'network' }}</p>
				</div>
			</div>
		</div>
		<div class="mode3">
			<div class="mode-tit">
				<div>{{ lang == 6 ? '过往的活动' : 'Overview of activities' }}</div>
				<img src="../../assets/paigWomen/logo.png" />
			</div>
			<div>
				<div class="mode3-list-item" v-for="(item, index) in activityList" :key="index">
					<div class="mode3-list-item-text">
						<p class="title">{{ item.title }}</p>
						<div v-html="item.describe" class="text"></div>
						<div class="time">
							<i class="el-icon-time"></i>
							<span>{{item.business_time}}</span>
						</div>
						<!-- <img src="../../assets/index/mode4-btn.png" @click="swiperPrev(index)" />
						<img src="../../assets/index/mode4-btn.png" @click="swiperNext(index)" /> -->
					</div>
					<div class="mode3-right">
						<img src="../../assets/arrows1.png" :style="item.swiperCur == 0 ? 'opacity: .3' : ''"
							class="arrows1" @click="swiperPrev(index)" />
						<img src="../../assets/arrows1.png"
							:style="item.swiperCur == item.atlas.split('|').length - 1 ? 'opacity: .3' : ''"
							class="arrows1" @click="swiperNext(index)" />
						<!-- <viewer :images="item.atlas.split('|')"> -->
						<swiper @slide-change-transition-end="handleClickSlide(index)" :options="swiperOption1"
							:ref="'mode3Swper' + index">
							<swiper-slide v-for="(image, j) in item.atlas.split('|')" :key="j">
								<!-- <img :src="image" mode="widthFit" /> -->
								<el-image :src="image" mode="widthFit" :preview-src-list="item.atlas.split('|')"
									class="prevImage"></el-image>
							</swiper-slide>
						</swiper>
						<!-- </viewer> -->
					</div>
				</div>
				<el-pagination v-if="(total / limit) > 1" background layout="prev, pager, next" :page-size="limit"
					:total="total" :current-page="page" @current-change="changePage">
				</el-pagination>
			</div>
		</div>
		<div class="mode3 mode4">
			<div class="mode-tit">
				<div>{{ lang == 6 ? '敬请期待' : 'Upcoming soon' }}</div>
				<img src="../../assets/paigWomen/logo.png" />
			</div>
			<div class="mode4-con">
				<div v-for="(item, index) in foreshow_list" :key="index">
					<img :src="item.image" />
					<p>{{ item.title }}</p>
				</div>
			</div>
			<el-pagination v-if="(foreshow_total / foreshow_limit) > 1" background layout="prev, pager, next"
				:page-size="foreshow_limit" :total="foreshow_total" :current-page="foreshow_page"
				@current-change="foreshow_changePage">
			</el-pagination>
		</div>
		<p class="tip">{{ lang == 6 ? '*联系我们：' : ' *Contact us:' }} women@paig.com.cn</p>
	</div>
</template>

<script>
	import mixinLang from '../../components/mixin.js'
	import {
		swiper,
		swiperSlide
	} from "vue-awesome-swiper/src/index.js";
	import 'swiper/dist/css/swiper.css';
	export default {
		mixins: [mixinLang],
		data() {
			return {
				info: {},
				page: 1,
				limit: 5,
				total: 0,
				foreshow_limit: 2,
				foreshow_page: 1,
				foreshow_total: 0,
				foreshow_list: [],
				activityList: [],
				swiperOption1: {
					slidesPerView: 1
				}
			}
		},
		components: {
			swiper,
			swiperSlide
		},
		methods: {
			getInfo() {
				this.getActivityList()
				this.getAtlasList()
			},
			swiperPrev(index) {
				var that = this
				that.$refs['mode3Swper' + index][0].swiper.slidePrev()
			},
			swiperNext(index) {
				var that = this
				that.$refs['mode3Swper' + index][0].swiper.slideNext()
			},
			handleClickSlide(index) {
				this.activityList[index].swiperCur = this.$refs['mode3Swper' + index][0].swiper.realIndex
			},
			foreshow_changePage(page) {
				this.foreshow_page = page
				this.getAtlasList()
			},
			// 改变分页
			changePage(page) {
				this.page = page
				this.getActivityList()
			},
			// 获取预告
			getAtlasList() {
				var that = this
				that.$api.getAtlasList({
					cate_no: 'fg7nt769m',
				}, {
					page: that.foreshow_page,
					limit: that.foreshow_limit
				}).then(res => {
					that.foreshow_list = res.list
					that.foreshow_total = Number(res.paginated.count)
				})
			},
			// 历史活动
			getActivityList(e = {}) {
				var that = this
				that.$api.getActivityList({
					cate_no: 'tj9879k70f',
					form: 'all'
				}, {
					page: that.page,
					limit: that.limit
				}).then(res => {
					res.list.forEach(item => item.swiperCur = 0)
					that.activityList = res.list
					that.total = Number(res.paginated.count)
				})
			},
		}
	}
</script>

<style lang="scss" scoped>
	.page {
		position: relative;
		padding: 180px 60px 0px;
	}

	/deep/ .text {
		font-family: SourceHanSansCN-Normal, sans-serif;
	}

	.pageTop {
		color: #FFF;
		padding: 0 60px;

		.tit {
			font-size: 40px;
			font-weight: bold;
			text-transform: uppercase;
		}

		.yellowLine {
			margin-bottom: 20px;
		}

		.flexBox {
			width: 100%;
			display: flex;
			justify-content: space-between;
			margin: 50px 0;

			// text-transform: uppercase;
			>div {
				width: 500px;
				border: 1px solid #FFFFFF;
				border-radius: 15px;
				text-align: center;
				padding: 50px 0;
				font-weight: 500;

				img {
					width: 160px;
					margin-bottom: 20px;
				}
			}
		}
	}

	.title {
		margin-bottom: 10px;
		padding: 0 60px;

		img {
			width: 218px;
			margin-top: 30px;
		}
	}

	.intro {
		// font-family: SourceHanSansCN-Normal,Microsoft YaHei New, Microsoft Yahei, 微软雅黑, Hiragino Sans GB, 冬青黑体, Arial, Helvetica, SimHei, 黑体, STXihei, 华文细黑, sans-serif;
		// font-size: 20px;
		font-weight: 500;
		color: #FFF;
		margin-top: 40px;

		.intro-txt {
			padding: 0 60px;
		}

		.logo {
			width: 120px;
			margin: 0 10px;
		}

		>img {
			width: 100%;
		}

		p {
			margin: 30px 60px;
			line-height: 40px;

			img {
				height: 20px;
				margin-right: 10px;
			}
		}

		.red {
			font-family: SourceHanSansCN-Bold, sans-serif;
			font-size: 32px;
			font-weight: bold;
			color: #97233F;
		}
	}

	.mode2 {
		border-radius: 16px;
		background: #EEEEEE;
		font-family: SourceHanSansCN-Medium, sans-serif;
		font-size: 26px;
		font-weight: 500;
		text-align: center;
		color: #97233F;
		padding: 40px 51px;
		margin-top: 40px;
		text-transform: uppercase;

		.mode2-con {
			display: flex;
			align-items: center;
			justify-content: space-between;

			>div {
				width: 500px;
				padding: 50px 0;
				// height: 300px;
				border-radius: 16px;
				border: 1px solid #97233F;

				img {
					width: 180px;
					height: 180px;
				}
			}
		}

	}

	.mode-tit {
		display: flex;
		font-size: 32px;
		font-weight: bold;
		color: #97233F;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 40px;
		text-transform: uppercase;

		img {
			width: 270px;
		}
	}

	.mode3 {
		margin-top: 40px;
		border-radius: 16px;
		overflow: hidden;
		box-sizing: border-box;
		background: #EEEEEE;

		.mode-tit {
			padding: 20px 51px;
			margin-bottom: 0;
		}

		.mode3-list-item {
			padding: 40px 51px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			box-sizing: content-box;
			height: 370px;
			background-color: #FFF;
			margin-bottom: 30px;

			.mode3-list-item-text {
				width: 910px;
				font-family: SourceHanSansCN-Regular, sans-serif;
				font-size: 18px;
				font-weight: normal;
				line-height: 40px;
				letter-spacing: 0em;
				color: #787878;
				position: relative;
				height: 100%;

				.title {
					text-transform: none;
					padding: 0;
					position: relative;
					margin-bottom: 30px;

					&::after {
						content: '';
						position: absolute;
						width: 106px;
						height: 4px;
						background-color: #97233F;
						bottom: -15px;
						left: 0;
					}
				}

				/deep/ p,
				/deep/ span {
					font-size: 24px;
					line-height: 40px;
				}

				>div:nth-child(2) {
					height: calc(100% - 100px);
					margin-bottom: 10px;
					width: 100%;
					overflow-y: scroll;
					padding-right: 20px;
					// overflow: hidden;
					// text-overflow: ellipsis;
				}
			}

			.time {
				display: flex;
				align-items: center;
				justify-content: end;
				padding-right: 20px;

				i {
					margin-right: 6px;
					font-size: 28px !important;
				}
			}

			.title {
				font-family: SourceHanSansCN-Bold, sans-serif;
				font-size: 26px;
				font-weight: bold;
				color: #97233F;
			}
		}

		.mode3-right {
			position: relative;
			height: 280px;

			&::after {
				width: 200px;
				height: 260px;
				background: #97233F;
				top: -15px;
				left: -15px;
				position: absolute;
				content: '';
			}

			/deep/.swiper-container {
				width: 540px;
			}

			/deep/ .swiper-slide {
				width: 520px;
			}

			.prevImage {
				width: 520px;
				height: 280px;
			}

			.arrows1 {
				width: 56px;
				height: 56px;
				position: absolute;
				left: -70px;
				z-index: 9;
				top: calc(50% - (56px/2));

				&:nth-child(2) {
					left: auto;
					right: -35px;
					transform: rotate(180deg);
				}
			}
		}
	}

	.el-pagination {
		text-align: center;
		margin: 50px 0;

		/deep/ .active {
			background-color: #97233F !important;
		}
	}

	.mode4-con {
		display: flex;
		font-family: SourceHanSansCN-Medium, sans-serif;
		font-size: 26px;
		font-weight: 500;
		color: #97233F;
		text-align: center;
		padding: 20px 51px;
		justify-content: space-between;
		line-height: 50px;

		>div {
			img {
				width: 800px;
				height: 400px;
			}
		}
	}

	.tip {
		color: #FFF;
		font-size: 28px;
		font-weight: 350;
		padding: 30px 111px;
	}
</style>